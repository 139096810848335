import styles from './links.page.module.css'
import horizontalLogo from '../../assets/logos/PNG sem fundo Mares e Fonseca-05.png'
// import {ReactComponent as Mail} from '../../assets/icons/mail_FILL0.svg'
import { ReactNode } from 'react'
import AlexImage from '../../assets/images/Avatar Alex.jpeg'
import { FaTiktok, FaInstagram, FaRegEnvelope, FaWhatsapp } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { IoIosGlobe } from "react-icons/io";
import { useParams, Navigate } from 'react-router-dom';

const Alex = {
    name: 'Dr. Alex Fonseca',
    image: AlexImage,
    mail: 'contato@maresefonseca.adv.br',
    whatsapp: '5537999367777',
    instagram: 'alexfonseca07',
    facebook: 'alex.fonseca.311',
    site: 'https://www.maresefonseca.adv.br',
    tiktok: '@dr.alexfonseca'
}


export function LinksPage(){
    const {profile} = useParams()
    const Profile = profile === 'alex' || profile === 'Alex' ? Alex : undefined
    if(!Profile) return <Navigate to={'/'} />
    return <div className={styles.Page}>
        <div className={styles.Content}>
            <div className={styles.AvatarContent}>
                <div className={styles.Avatar}>
                    <img className={styles.AvatarImage} src={Profile.image} alt=' ' />
                </div>
            </div>

            <div className={styles.Title}>
                <h2>{Alex.name}</h2>
            </div>

            {/* <div className={styles.Icons}>
                <div className={styles.IconsRow}>
                    <IconItem title='E-MAIL' icon={<Mail width={'100%'} fill='var(--color1)' />} link='mailto:contato@maresefonseca.adv.br' />
                    <IconItem title='WHATSAPP' icon={<Mail width={'100%'} />} link='https://api.whatsapp.com/send/?phone=5537999367777&text&type=phone_number&app_absent=0' />
                    <IconItem title='INSTAGRAM' icon={<Mail width={'100%'} />} link='https://www.instagram.com/alexfonseca07/' />
                </div>
                <div className={styles.IconsRow}>
                    <IconItem title='FACEBOOK' icon={<Mail width={'100%'} />} link='https://www.facebook.com/alex.fonseca.311' />
                    <IconItem title='SITE' icon={<Mail width={'100%'} />} link='https://www.maresefonseca.adv.br' />
                    <IconItem title='TIKTOK' icon={<Mail width={'100%'} />} link='https://www.tiktok.com/@dr.alexfonseca' />
                </div>
            </div> */}
            <div className={styles.Icons}>
                <div className={styles.IconsRow}>
                    <IconItem title='E-MAIL' icon={<FaRegEnvelope size={50} fill='var(--color1)' />} link={`mailto:${Profile.mail}`} />
                    <IconItem title='WHATSAPP' icon={<FaWhatsapp size={50}  fill='var(--color1)' />} link={`https://api.whatsapp.com/send/?phone=${Profile.whatsapp}&text&type=phone_number&app_absent=0`} />
                    <IconItem title='INSTAGRAM' icon={<FaInstagram size={50}  fill='var(--color1)' />} link={`https://www.instagram.com/${Profile.instagram}`} />
                </div>
                <div className={styles.IconsRow}>
                    <IconItem title='FACEBOOK' icon={<FaFacebookF size={50}  fill='var(--color1)' />} link={`https://www.facebook.com/${Profile.facebook}`} />
                    <IconItem title='SITE' icon={<IoIosGlobe size={50}  fill='var(--color1)' />} link={Profile.site} />
                    <IconItem title='TIKTOK' icon={<FaTiktok size={50}  fill='var(--color1)' />} link={`https://www.tiktok.com/${Profile.tiktok}`} />
                </div>
            </div>

            <div className={styles.Advertise}>
                <div className={styles.AdvertiseContent}>
                <h4>CLIQUE NOS ÍCONES</h4>
                </div>
            </div>

            <div className={styles.Logo}>
            <a href='/'><img className={styles.LogoImage} src={horizontalLogo} alt='' /></a>
            </div>
        </div>
    </div>
}


type IconItemProps = {
    icon: ReactNode,
    title: string,
    link: string
}

function IconItem({icon, title, link}: IconItemProps){
    return <a className={styles.Icon} href={link}>
        <div className={styles.IconAvatar}>
            <div className={styles.IconImage}>{icon}</div>
        </div>
        <div className={styles.IconTitle}>
            <h5>{title}</h5>
        </div>
    </a>
}