import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { MaintenancePage } from './pages/maintenance';
import { HomePage } from './pages/home/home.page';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QuemSomosPage } from './pages/quem-somos/quem-somos.page';
import { AreasDeAtuacaoPage } from './pages/areas-de-atuacao/areas-de-atuacao.page';
import { NossaEquipePage } from './pages/nossa-equipe/nossa-equipe.page';
import { EstruturaPage } from './pages/estrutura/estrutura.page';
import { ContatoPage } from './pages/contato/contato.page';
import { LinksPage } from './pages/links/links.page';

const isInMaintance = false

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/quem-somos",
    element: <QuemSomosPage />,
  },
  {
    path: "/areas-de-atuacao",
    element: <AreasDeAtuacaoPage />,
  },
  {
    path: "/nossa-equipe",
    element: <NossaEquipePage />,
  },
  {
    path: "/estrutura",
    element: <EstruturaPage />,
  },
  {
    path: "/contato",
    element: <ContatoPage />,
  },
  {
    path: '/links/:profile',
    element: <LinksPage />
  }
])

const maintenanceRouter = createBrowserRouter([
  {
    path: '/',
    element: <MaintenancePage />
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={isInMaintance ? maintenanceRouter : router} />
    {/* <HomePage /> */}
    {/* <MaintenancePage /> */}
  </React.StrictMode>
);
